<template>
  <v-container>
    <v-row>
      <v-col>
        <v-btn color="primary">Check Fails</v-btn>
        <v-btn color="primary" class="ml-5">Delete Fails</v-btn>
        {{ pixelsFail }}
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';

  export default {
    data() {
      return {
        
      }
    },

    computed: {
      ...mapState(['pixels']),

      pixelsFail () {
        return this.pixels.filter(item => !item.sku)
      },

    },
  }


</script>